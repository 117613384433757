<template>
	<div class="data-list-container main-list-view ml-6 mt-12">
		<!-- title name -->
		<event-modal
			:isModalActive="addNewDataModal"
			:data="modalData"
			@closeModal="toggleDataModal"
			@add="insert"
		/>
		<vs-table
			class=""
			ref="table"
			search
			sst
			:pagination="false"
			:data="events"
			:max-items="limit"
			:total="totalEvents"
			v-model="selected"
			@search="onSearch"
			@sort="onSort"
			:options="tableOptions"
			@change-page="changePage">
			<div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
				<!-- TITLE -->
				<div class="p-0" style="font-size: 28px!important">{{ !isArchived ? $t('Active Events') : $t('Archived Events')}} {{ !isMine ? `(${$t('All Event')})` : `(${$t('My Event')})` }}</div>
				<!-- ADD NEW -->
				<div class="flex items-center">
					<div @click="isMine = !isMine" class="mr-4 pointer font-medium hover:text-primary">{{!isMine ? $t('View My Event') : $t('View All Events')}}</div>
					<div @click="isArchived = !isArchived" class="mr-4 pointer font-medium hover:text-primary">{{!isArchived ? $t('View Archived') : $t('View Active Events')}}</div>
					<div
						class="btn-add-new px-4 py-25 mr-4 vs-button vs-button-primary vs-button-filled rounded-lg cursor-pointer flex items-center justify-center"
						@click="addNewData" v-if="isSuperAdmin || isClient">
						<feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
						<span class="ml-2">{{ $t('Create Event') }}</span>
					</div>
				</div>
			</div>
			<template slot-scope="{data}">
				<div class="events-container">
					<div :data="item" :key="indexitem" v-for="(item, indexitem) in data" @dblclick.stop="openDetails(item)">
						<vs-card actionable class="cardx-large event">
							<vs-dropdown class="dd-actions cursor-pointer event-menu" vs-trigger-click>
								<div>
									<feather-icon style="z-index: 9999999" icon="MoreVerticalIcon" svgClasses="h-7 w-7" />
								</div>
								<vs-dropdown-menu>
									<!-- present mode -->
									<vs-dropdown-item>
										<div class="flex items-center" @click="openPresentMode(item)">
											<span>{{ $t('Present Mode') }}</span>
										</div>
									</vs-dropdown-item>
									<!-- participant mode -->
									<!-- <vs-dropdown-item>
										<div class="flex items-center">
											<span>{{ $t('Participant Mode') }}</span>
										</div>
									</vs-dropdown-item> -->
									<!-- view -->
									<vs-dropdown-item>
										<div class="flex items-center" @click="openDetails(item)">
											<span>{{ $t('Open') }}</span>
										</div>
									</vs-dropdown-item>
                  <!-- view event -->
									<vs-dropdown-item>
										<div class="flex items-center" @click="openEvent(item)">
											<span>{{ $t('View event') }}</span>
										</div>
									</vs-dropdown-item>
									<!-- edit -->
									<vs-dropdown-item>
										<div class="flex items-center" @click="editData(item)">
											<span>{{ $t('Edit') }}</span>
										</div>
									</vs-dropdown-item>
									<!-- duplicate-->
									<vs-dropdown-item v-if="!isTeam" @click="duplicate(item.id)">
										<div class="flex items-center">
											<span>{{ $t('Duplicate') }}</span>
										</div>
									</vs-dropdown-item>
                  <!-- Copy Embed Code -->
									<vs-dropdown-item @click="copyEmbedCode(item)" v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
										<div class="flex items-center">
											<span>{{ $t('Copy Embed Code') }}</span>
										</div>
									</vs-dropdown-item>
									<!-- archive -->
									<vs-dropdown-item v-if="!isTeam" @click="archive(item)">
										<div class="flex items-center">
											<span>{{ isArchived ? $t('Unarchive') : $t('Archive') }}</span>
										</div>
									</vs-dropdown-item>
									<!-- delete -->
									<vs-dropdown-item v-if="!isTeam" @click="dialogImport = true">
										<div class="flex items-center" @click="deleteData(item)">
											<span>{{ $t('Delete') }}</span>
										</div>
									</vs-dropdown-item>
								</vs-dropdown-menu>
							</vs-dropdown>
							<a>
								<div class="title font-bold" @click.stop="openDetails(item)">{{ item.name }}</div>
							</a>
							<div class="access-code" @click.stop="openDetails(item)">#{{ item.code }}</div>
							<p class="date truncate mt-3" @click.stop="openDetails(item)">{{ dateFormat1(item.start_date, item.timezone) }} - {{ dateFormat2(item.end_date, item.timezone) }}</p>
							<!-- <p v-if="item && item.timezone" class="timezone truncate mt-3" @click.stop="openDetails(item)">{{ userTimezone }}</p> -->
              <div class="text-secondary owner">{{ item && item.user && item.user.name }}</div>
							<div class="flex justify-between flex-wrap"></div>
						</vs-card>
					</div>
				</div>
			</template>
		</vs-table>
		<!-- Pagination -->
		<Pagination
			class="mt-3"
			:total="totalPages"
			:max-items="limit"
			:size-array="totalEvents"
			v-model="page">
		</Pagination>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/api/event';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  COUNTRY_CODE,
} from '@/lib/statics';
import {
  duplicateVar,
  delay,
  getAxiosErrorMessage,
} from '@/lib/helper';
import EventModal from '@/components/EventModal.vue';
import Pagination from '@/components/Pagination.vue';

dayjs.extend(utc);
dayjs.extend(timezone);
const ct = require('countries-and-timezones');

export default {
  name: 'Events',
  components: {
    EventModal,
    Pagination,
  },
  data() {
    return {
      keyword: '',
      events: [],
      isFetching: false,
      isDuplicate: false,
      isAll: false,
      page: 1,
      limit: 15,
      orderBy: 'created_at',
      sortBy: 'desc',
      totalPages: 0,
      totalEvents: 0,
      selected: [],
      selectedItem: null,
      isFormVisible: false,
      isDeleteVisible: false,
      isDeleting: false,
      isMounted: false,
      addNewDataModal: false,
      modalData: {},
      windowWidth: window.innerWidth,
      isArchived: false,
      isMine: true,
      tableOptions: {
        search: 'Enter your search query...', // Set the placeholder text here
      },
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    dateFormat1(t, tz) {
      const osloDateString = t.slice(0, -1); // Assuming this is in Oslo time
      const osloTime = dayjs.tz(osloDateString, tz);
      const utcTime = osloTime.tz(dayjs.tz.guess());
      return utcTime.format('DD MMM YYYY hh:mm A');
    },
    dateFormat2(t, tz) {
      const osloDateString = t.slice(0, -1); // Assuming this is in Oslo time
      const osloTime = dayjs.tz(osloDateString, tz);
      const utcTime = osloTime.tz(dayjs.tz.guess());
      return utcTime.format('hh:mm A');
    },
    convertTZ(date, tzString) {
      return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
    },
    fetch(reset) {
      this.$vs.loading();
      this.isFetching = true;
      if (reset) {
        this.page = 1;
        this.events = [];
      }
      const keyword = this.keyword;
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
      };
      if (keyword !== '') {
        params.keyword = keyword;
      }
      if (this.isArchived) params.is_archived = 1;
      if (!this.isArchived) params.is_archived = 0;
      if (this.isMine) params.is_mine = 1;
      if (!this.isMine) delete params.is_min;
      const callback = (response) => {
        const events = response.data;
        const lastPage = response.last_page;
        this.events = events;
        this.isAll = events.length === 0 || events.length < this.limit;
        // this.page++;
        if (lastPage) {
          this.totalPages = lastPage;
        }
        this.totalEvents = response.total;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      eventsApi.getList(params, callback, errorCallback);
    },
    duplicate(id) {
      this.$vs.loading();
      this.isDuplicate = true;

      const callback = (response) => {
        const event = response.data;
        if (this.events && this.events.length > 15) {
          this.fetch(true);
        } else {
          this.insert(event);
        }
        this.isDuplicate = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isDuplicate = false;
        this.$vs.loading.close();
      };
      eventsApi.duplicate(id, callback, errorCallback);
    },
    archive(item) {
      this.$vs.loading();
      const params = {
        is_archived: item && item.is_archived === 1 ? 0 : 1,
      };
      const notifTitle = this.$t('Event');
      const callback = (response) => {
        const item = response.data;
        const message = response.message;
        this.remove(item.id);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      eventsApi.update(item.id, params, callback, errorCallback);
    },
    addNewData() {
      this.modalData = {};
      this.toggleDataModal(true);
    },
    deleteData(item) {
      this.selectedItem = duplicateVar(item);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')} "${item.name}"?`,
        accept: this.deleteRecord,
        acceptText: this.$t('Delete'),
      });
    },
    bulkDelete() {
      const total = this.selected.length;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')} ${total} ${this.$t('Events')}?`,
        accept: this.deleteRecords,
        acceptText: this.$t('Delete'),
      });
    },
    editData(data) {
      this.selectedItem = duplicateVar(data);
      this.modalData = duplicateVar(data);
      this.toggleDataModal(true);
    },
    toggleDataModal(val = false) {
      this.addNewDataModal = val;
    },
    onSearch(keyword) {
      delay(() => {
        this.keyword = keyword ? keyword.trim() : '';
        this.fetch(true);
      }, 1000);
    },
    onSort(sortKey, sortType) {
      this.orderBy = sortKey;
      this.sortBy = sortType;
      if (this.orderBy && this.sortBy) this.fetch(true);
    },
    deleteRecord() {
      this.$vs.loading();
      const notifTitle = this.$t('Event');
      const item = this.selectedItem;
      const callback = (response) => {
        if (this.totalPages > 1) {
          this.fetch(true);
        } else {
          this.remove(item.id);
        }
        const message = response.message;
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      eventsApi.delete(item.id, callback, errorCallback);
    },
    deleteRecords() {
      const notifTitle = this.$t('Event');
      const ids = this.selected.map(({ id }) => id);
      const callback = (response) => {
        ids.forEach((id) => {
          this.remove(id);
        });
        const message = response.message;
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      eventsApi.bulkDelete(ids, callback, errorCallback);
    },
    insert(item) {
      const index = this.events.findIndex(({ id }) => id === item.id);
      if (this.events.length < 15) {
        if (index === -1) {
          this.events.unshift(item);
          this.totalEvents++;
        } else {
          this.$set(this.events, index, item);
        }
      } else if (this.events.length >= 15) {
        if (index === -1) {
          this.fetch(true);
          this.totalEvents++;
        } else {
          this.$set(this.events, index, item);
        }
      }
    },
    remove(itemId) {
      const index = this.events.findIndex(({ id }) => id === itemId);
      if (index !== -1) {
        this.events.splice(index, 1);
        this.totalEvents--;
      }
    },
    changeLimit(value) {
      this.limit = value;
      this.fetch(true);
    },
    changePage(page) {
      this.page = page;
    },
    onSelectAll(event) {
      const elem = event.target;
      const isCheckbox = elem.classList.contains('vs-checkbox--input');
      const isCheckAll = !!elem.closest('.vs-table--thead');
      if (isCheckbox && isCheckAll) {
        const isChecked = elem.checked;
        if (isChecked) {
          this.selected = this.events;
        } else {
          this.selected = [];
        }
      }
    },
    isOwner(item) {
      const isOwner = this.user && this.user.id === item.user_id;
      return isOwner;
    },
    openDetails(item) {
      this.$router.push({ name: 'EventDetails', params: { slug: item.slug } });
    },
    openEvent(item) {
      const routeData = this.$router.resolve({
        name: 'EventLive',
        params: {
          eventLinkCode: item.link_code_value,
        },
      });
      window.open(routeData.href, '_blank');
    },
    openPresentMode(item) {
      // open url in new tab
      const url = `${process.env.VUE_APP_APP_URL}/events/${item.slug}/display`;
      window.open(url, '_blank');
    },
    liveViewDetails(item) {
      this.$router.push({ name: 'EventLive', params: { eventSlug: item.slug } });
    },
    generateInitial(event) {
      const words = event.name ? event.name.split(' ') : 'AN';
      const initial = words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0][0];
      return initial;
    },
    copyEmbedCode(event) {
      const baseUrl = event && event.link_code ? event.link_code : '';
      const embedUrl = `${baseUrl}`;
      // Generate the embed code
      const embedCode = `<iframe width="100%" height="1200" src="${embedUrl}" title="${event.name}" frameborder="0" allowfullscreen></iframe>`;
      this.$copyText(embedCode).then(() => {
        this.onCopy();
      }, (e) => {
        this.onError();
        console.log(e);
      });
    },
    onCopy() {
      this.$vs.notify({
        text: 'Copied to clipboard',
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
    },
    onError() {
      this.$vs.notify({
        title: 'Failed',
        text: 'Error in copying',
        color: 'danger',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-alert-circle',
      });
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    isSuperAdmin() {
      return this.user && this.user.roles.includes('super_admin');
    },
    isClient() {
      return this.user && this.user.roles.includes('client');
    },
    isTeam() {
      return this.user && this.user.roles.includes('team');
    },
    listClass() {
      const myClass = this.windowWidth > 1670 ? 'xs:w-1/1 sm:w-1/2 lg:w-1/4 pr-8' : 'xs:w-1/1 sm:w-1/2 lg:w-1/3 pr-8';
      return myClass;
    },
    userTimezone() {
      const timezone = this.user.settings ? this.user.settings : '';
      const parseTimezone = JSON.parse(timezone);
      const finalTimezone = parseTimezone && parseTimezone.timezone ? parseTimezone.timezone : '';
      const now = dayjs(new Date());
      const getCountryBytimezone = ct.getTimezone(finalTimezone);
      let finalUTC = '';
      if (getCountryBytimezone && getCountryBytimezone.countries && getCountryBytimezone.countries[0]) {
        finalUTC = `(UTC${dayjs.tz(now, finalTimezone).format('Z')}) ${COUNTRY_CODE[0][getCountryBytimezone.countries[0]]}`;
        console.log('final', finalUTC);
      } else {
        console.log('Timezone not defined');
      }
      console.log('final', finalUTC);
      return finalUTC;
    },
  },
  created() {
    this.fetch();
    document.addEventListener('click', this.onSelectAll);
  },
  destroyed() {
    document.removeEventListener('click', this.onSelectAll);
  },
  mounted() {
    console.log((dayjs.tz.guess()));

    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };

    // add place holder on search input
    const searchInput = document.querySelector('.vs-table--search-input');
    if (searchInput) {
      searchInput.placeholder = this.$t('Search'); // Set the desired placeholder text
    }

    // overide no available text data in vue sax
    const messageNotAvailableTable = this.$t('No data Available');
    // Retrieve the element
    const notDataTableElement = document.querySelector('.not-data-table');
    // Check if the element exists
    if (notDataTableElement && !this.isFetching) {
      // Add localization (replace the text with your localized version)
      notDataTableElement.innerHTML = messageNotAvailableTable;
    }
  },
  watch: {
    page() {
      this.fetch();
    },
    isArchived() {
      this.fetch(true);
    },
    isMine() {
      this.fetch(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.cardx-md {
	position: relative;
	min-width: 260px;
	max-width: 312px;
}
.cardx-large {
	position: relative;
	min-width: 312px;
}
.card-img-top {
	border-top-left-radius: .5rem;
	border-top-right-radius: .5rem;
	max-height: 210px;
	width: 100%;
	height: 100%;
	object-fit: cover;
	min-height: 175px;
    max-height: 175px;
}
.item-name {
	font-size: 14px;
	color: #000;
}
.item-date {
	color: #b1b1b1;
}
.event-menu {
	position: absolute;
	top: 10px;
	right: 10px;
}
.wrapper-event{
	overflow-x: hidden!important;
}
</style>
